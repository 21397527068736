
/**
 * 网站发布路径
 * /usr/share/nginx/html/landcoo
 */

const basePath = 'https://res.landcoo.com:1060'
const appName = 'landcoo'

const appBase = {
  prep: 'land.web',
  name: appName,
  title: '蓝库软件',
  version: '1.0.0',
  url_base: basePath,
  res_url: `${basePath}/${appName}`,
  res_skin: `${basePath}/${appName}/skin`,
  h5_url: `${basePath}/${appName}/h5/`,
  office_url: 'https://office.huishuapp.com',
}

export default appBase

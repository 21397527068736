// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/pages/gw/Home.vue'),
        },
        {
          path: 'scene',
          name: 'Scene',
          component: () => import('@/pages/gw/Scene.vue'),
        },
        {
          path: 'scheme',
          name: 'Scheme',
          component: () => import('@/pages/gw/Scheme.vue'),
        },
        {
          path: 'support',
          name: 'Support',
          component: () => import('@/pages/gw/Support.vue'),
          meta: { dark: true },
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/pages/gw/About.vue'),
          meta: { dark: true },
        },
        {
          path: 'scene/detail',
          name: 'SceneDetail',
          component: () => import('@/pages/gw/scene/Detail.vue'),
        },
        {
          path: 'scheme/detail',
          name: 'SchemeDetail',
          component: () => import('@/pages/gw/scheme/Detail.vue'),
        },
        // {
        //   path: 'my/login',
        //   name: 'Login',
        //   component: () => import('@/pages/landcoo/my/Login.vue'),
        // },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router

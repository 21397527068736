
import app from '@/api/co.app'

const dtBanner = {
  home: [{
    bannerId: '1001',
    cover: app.base.res_url + '/img/ban-home1.png',
    title: '云采客房监查服务平台',
    type: 'home-major',
  }, {
    bannerId: '1002',
    cover: app.base.res_url + '/img/ban-home2.png',
    title: '云采安全生产环境监测服务平台',
    type: 'home-major',
  }],
  scene: [{
    bannerId: '2003',
    cover: app.base.res_url + '/img/ban-scene.png',
    title: '应用场景',
  }],
  scheme: [{
    bannerId: '3004',
    cover: app.base.res_url + '/img/ban-scheme.png',
    title: '解决方案',
  }],
  support: [{
    bannerId: '4005',
    cover: app.base.res_url + '/img/ban-support.jpg',
    title: '技术支持',
  }],
  about: [{
    bannerId: '5006',
    cover: app.base.res_url + '/img/ban-about.jpg',
    title: '关于我们',
  }],
}

export default dtBanner

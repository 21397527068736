
import app from '@/api/co.app'
import SceneTypes from './data.scene.type'

const Types = SceneTypes

const SceWeiSheng = {
  key: 'img-1-1',
  contentId: '10001',
  title: '卫生服务中心',
  cover: app.base.res_url + '/img/sce-hospital.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.SCENE,
  thumbs: [
    app.base.res_url + '/img/sce-hospital.jpg',
    app.base.res_url + '/img/sce-hospital.jpg'
  ],
  relations: [],
}

const SceGongYe = {
  key: 'img-1-2',
  contentId: '10002',
  title: '工业园区',
  cover: app.base.res_url + '/img/sce-industry.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.SCENE,
  thumbs: [
    app.base.res_url + '/img/sce-zzy01.jpg',
    app.base.res_url + '/img/sce-whp02.jpg',
    app.base.res_url + '/img/sce-zzy03.jpg',
    app.base.res_url + '/img/sce-zzy04.jpg',
  ],
  relations: [],
}

const SceNongYe = {
  key: 'img-1-3',
  contentId: '10003',
  title: '智慧农业及养殖',
  cover: app.base.res_url + '/img/sce-agriculture.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.AGRICULTURE,
  thumbs: [
    app.base.res_url + '/img/sce-yz01.jpg',
    app.base.res_url + '/img/sce-yz03.jpg',
    app.base.res_url + '/img/sce-yz04.jpg',
  ],
  relations: [],
}

const SceJiuDian = {
  key: 'img-1-4',
  contentId: '10004',
  title: '酒店及网约房',
  cover: app.base.res_url + '/img/sce-hotel.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.HOTEL,
  thumbs: [],
}

const SceWeiHuaPing = {
  key: 'img-1-5',
  contentId: '10005',
  title: '危化品实验室',
  cover: app.base.res_url + '/img/sce-laboratory-2.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.CHEMICAL,
  thumbs: [
    app.base.res_url + '/img/sce-whp01.jpg',
    app.base.res_url + '/img/sce-whp02.jpg',
    app.base.res_url + '/img/sce-whp03.jpg',
    app.base.res_url + '/img/sce-whp04.jpg',
  ],
  relations: [],
}

const SceSheQu = {
  key: 'img-1-6',
  contentId: '10006',
  title: '智慧社区',
  cover: app.base.res_url + '/img/sce-building.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.BUILDING,
  thumbs: [],
  relations: [],
}

const SceZhiZhaoYe = {
  key: 'img-1-7',
  contentId: '10007',
  title: '制造业园区',
  cover: app.base.res_url + '/img/sce-industry01.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.INDUSTRY,
  thumbs: [
    app.base.res_url + '/img/sce-zzy01.jpg',
    app.base.res_url + '/img/sce-whp02.jpg',
    app.base.res_url + '/img/sce-zzy03.jpg',
    app.base.res_url + '/img/sce-zzy04.jpg',
  ],
  relations: [],
}

const SceHuaGong = {
  key: 'img-1-8',
  contentId: '10008',
  title: '化工园区',
  cover: app.base.res_url + '/img/sce-industry02.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.INDUSTRY,
  thumbs: [
    app.base.res_url + '/img/sce-hg01.jpg',
    app.base.res_url + '/img/sce-whp02.jpg',
    app.base.res_url + '/img/sce-hg03.jpg',
    app.base.res_url + '/img/sce-hg04.jpg',
  ],
  relations: [],
}

const SceZhenSuo = {
  key: 'img-1-9',
  contentId: '10009',
  title: '诊所',
  cover: app.base.res_url + '/img/sce-hospital01.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.HOSPITAL,
  thumbs: [],
  relations: [],
}

const SceWeiSheng02 = {
  key: 'img-1-10',
  contentId: '10010',
  title: '卫生服务中心',
  cover: app.base.res_url + '/img/sce-hospital02.jpg',
  banner: app.base.res_url + '/img/ban-scene01.jpg',
  type: Types.HOSPITAL,
  thumbs: [],
  relations: [],
}

const SceneList = {
  WeiSheng: SceWeiSheng,
  GongYe: SceGongYe,
  NongYe: SceNongYe,
  JiuDian: SceJiuDian,
  WeiHuaPing: SceWeiHuaPing,
  SheQu: SceSheQu,
  ZhiZhaoYe: SceZhiZhaoYe,
  HuaGong: SceHuaGong,
  ZhenSuo: SceZhenSuo,
  WeiSheng02: SceWeiSheng02,
}

export default SceneList


import app from '@/api/co.app'

const SchemeResumes = {
  ZhiZaoYe: {
    contentId: '20001',
    banner: app.base.res_url + '/img/ban-scheme01.jpg',
    cover: app.base.res_url + '/img/sch-zhizaoye.jpg',
    backColor: 'white',
    assistColor: 'light-blue',
    header: {
      title: '制造业安全生产解决方案',
      subtitle: '',
    },
    content: {
      title: '云采制造业安全生产服务平台',
      text: '利用先进的物联网技术，构建更加科学、高效、便捷的安全生产管理体系。实现管理创新和技术创新。同时降低安全生产管理成本，提高安全生产管理执行效率，实现安全生产信息数字化。',
      showIndicator: false,
      imageWidth: 560,
      imageHeight: 360,
      images: [
        {
          src: app.base.res_url + '/img/sch-zhizaoye.jpg',
        },
      ],
      button: {
        text: '浏览详情',
        backColor: 'light-blue',
        foreColor: 'white'
      }
    },
    thumbs: [
      app.base.res_url + '/img/sch-zzy01.jpg',
      app.base.res_url + '/img/sch-zzy02.jpg',
      app.base.res_url + '/img/sch-zzy03.jpg',
      app.base.res_url + '/img/sch-zzy04.jpg',
    ],
    relations: [],
  },
  HuaGong: {
    contentId: '20002',
    banner: app.base.res_url + '/img/ban-scheme01.jpg',
    cover: app.base.res_url + '/img/sch-huagong.jpg',
    backColor: 'grey lighten-4',
    assistColor: 'cyan',
    space: 20,
    header: {
      title: '化工安全生产解决方案',
      subtitle: '',
    },
    content: {
      title: '云采化工安全生产服务平台',
      text: '利用先进的物联网技术，创新安全生产管理模式，实现安全生产任务下达、检查、督查、巡查、整改、查询、储存、台账、环境实时监测、自动告警等全流程无纸化、信息化控制，从而构建更加科学、高效、便捷的安全生产管理体系。',
      reversed: true,
      showIndicator: false,
      imageWidth: 560,
      imageHeight: 360,
      images: [
        {
          src: app.base.res_url + '/img/sch-huagong.jpg',
        },
      ],
      button: {
        text: '浏览详情',
        backColor: 'cyan',
        foreColor: 'white'
      },
    },
    thumbs: [
      app.base.res_url + '/img/sch-hg01.jpg',
      app.base.res_url + '/img/sch-hg02.jpg',
      app.base.res_url + '/img/sch-hg03.jpg',
      app.base.res_url + '/img/sch-hg04.jpg',
    ],
    relations: [],
  },
  WeiHuaPin: {
    contentId: '20003',
    banner: app.base.res_url + '/img/ban-scheme01.jpg',
    cover: app.base.res_url + '/img/sch-weihuapin.jpg',
    backColor: 'white',
    assistColor: 'blue',
    header: {
      title: '危化品实验室安全解决方案',
      subtitle: '',
    },
    content: {
      title: '云采危化品安全服务平台',
      text: '利用先进的物联网技术，创新安全生产管理模式，实现存储、使用、危废处理、环境实时监测、应急响应等全流程无纸化、信息化控制，从而构建更加科学、高效、便捷的安全生产管理体系。',
      showIndicator: false,
      imageWidth: 560,
      imageHeight: 360,
      images: [
        {
          src: app.base.res_url + '/img/sch-weihuapin.jpg',
        },
      ],
      button: {
        text: '浏览详情',
        backColor: 'blue',
        foreColor: 'white'
      }
    },
  },
  JiuDian: {
    contentId: '20004',
    banner: app.base.res_url + '/img/ban-scheme01.jpg',
    cover: app.base.res_url + '/img/sch-huagong.jpg',
    backColor: 'grey lighten-4',
    assistColor: 'indigo',
    header: {
      title: '酒店房间人数实时监测解决方案',
      subtitle: '',
    },
    features: [{
      icon: 'mdi-shield-lock-outline',
      iconColor: 'indigo',
      title: '隐私保护',
      subtitle: '通过毫米波雷达探测人数，不会捕捉图像或录音'
    }, {
      icon: 'mdi-monitor-cellphone',
      iconColor: 'indigo',
      title: '实时监测',
      subtitle: '实时探测人数，平台实时知道每个房间的人数变化'
    }, {
      icon: 'mdi-bell-outline',
      iconColor: 'indigo',
      title: '及时预警',
      subtitle: '房间人数超出阈值后平台发出告警并提醒客服核实'
    }, {
      icon: 'mdi-security',
      iconColor: 'indigo',
      title: '信息安全',
      subtitle: '平台保障了信息数据的安全并通过CANS测试认证'
    }],
    button: {
      text: '浏览详情',
      backColor: 'indigo',
      foreColor: 'white'
    }
  },
  NongYe: {
    contentId: '20005',
    banner: app.base.res_url + '/img/ban-scheme01.jpg',
    cover: app.base.res_url + '/img/sch-nongye.jpg',
    backColor: 'white',
    assistColor: 'green',
    space: 20,
    header: {
      title: '智慧农业及养殖解决方案',
      subtitle: '',
    },
    content: {
      title: '智慧农业及养殖服务平台',
      text: '智慧农业及养殖主要涵盖了养殖环境监控、精细饲喂管理、疫病防控与诊断、养殖数据分析与决策支持以及远程监控与管理等多个方面，为现代养殖业的发展提供了有力的技术支持。',
      reversed: true,
      showIndicator: false,
      imageWidth: 560,
      imageHeight: 360,
      images: [
        {
          src: app.base.res_url + '/img/sch-nongye.jpg',
        },
      ],
      button: {
        text: '浏览详情',
        backColor: 'green',
        foreColor: 'white'
      }
    },
  },
  WeiSheng: {
    contentId: '20006',
    banner: app.base.res_url + '/img/ban-scheme01.jpg',
    cover: app.base.res_url + '/img/sch-huagong.jpg',
    backColor: 'grey lighten-4',
    assistColor: 'teal',
    header: {
      title: '卫健委安全生产服务平台',
      subtitle: '',
    },
    features: [{
      icon: 'mdi-fire-hydrant',
      iconColor: 'teal',
      title: '消防检查',
      subtitle: '针对机构所有消防设施按照要求逐一检查并上传照片'
    }, {
      icon: 'mdi-bottle-tonic-plus-outline',
      iconColor: 'teal',
      title: '危化品',
      subtitle: '危化品的入库、出库、领用、检查等流程全程跟踪'
    }, {
      icon: 'mdi-monitor-dashboard',
      iconColor: 'teal',
      title: '培训及演练',
      subtitle: '定期进行安全知识的培训，提高安全意识和自我保护能力'
    }, {
      icon: 'mdi-table-question',
      iconColor: 'teal',
      title: '整改及台账',
      subtitle: '自动生成整改清单及台账，并提示工作人员完成整改事项'
    }],
    button: {
      text: '浏览详情',
      backColor: 'teal',
      foreColor: 'white'
    }
  },
}

export default SchemeResumes


const SceneTypes = {
  HOSPITAL: 'hospital',
  INDUSTRY: 'industry',
  AGRICULTURE: 'agriculture',
  HOTEL: 'hotel',
  CHEMICAL: 'chemical',
  BUILDING: 'building',
  SCENE: 'scene',
}

export default SceneTypes


import simples from './data.scheme.simples'
import resumes from './data.scheme.resumes'

const dtScheme = {
  items: [
    simples.JiuDian,
    simples.HuaGong,
    simples.WeiSheng,
  ],
  simples,
  resumes,
  getItem (contentId = '') {
    let result = {}
    const me = dtScheme
    for (const i in me.resumes) {
      const item = me.resumes[i]
      if (item.contentId === contentId) {
        result = item
      }
    }
    return result
  },
}

export default dtScheme

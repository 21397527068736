
import app from '@/api/co.app'
import st from './data.scene.type'
import sl from './data.scene.list'

const Types = st

const dtScenes = {
  Types,
  items: [],
  guideLab: {
    contentId: sl.NongYe.contentId,
    cover: app.base.res_url + '/img/sce-gd-agriculture.jpg',
    title: sl.NongYe.title,
    header: {
      title: '智慧农业及养殖应用场景',
      english: '',
      subtitle: '实时监测养殖场内的温度、湿度等条件，提供养殖环境控制，提高养殖效率',
    },
    button: {
      text: '查看详细',
      backColor: 'green',
      foreColor: 'white'
    },
  },
  guideHotel: {
    contentId: sl.JiuDian.contentId,
    cover: app.base.res_url + '/img/sce-gd-hotel.jpg',
    title: sl.JiuDian.title,
    header: {
      title: '酒店及网约房应用场景',
      english: '',
      subtitle: '随着科技的进步，通过智能化手段监督酒店合规化管理成为重要趋势',
    },
    button: {
      text: '查看详细',
      backColor: 'blue',
      foreColor: 'white'
    },
  },
  initialize () {
    const me = dtScenes

    me.configRelations()

    me.items = [
      sl.WeiSheng,
      sl.GongYe,
      sl.NongYe,
      sl.JiuDian,
      sl.WeiHuaPing,
      sl.SheQu,
      sl.ZhiZhaoYe,
      sl.HuaGong,
      sl.ZhenSuo,
      sl.WeiSheng02,
    ]
  },
  configRelations () {
    let sce = sl.WeiHuaPing
    sce.relations = [{
      contentId: sl.ZhiZhaoYe.contentId,
      title: sl.ZhiZhaoYe.title,
    }, {
      contentId: sl.HuaGong.contentId,
      title: sl.HuaGong.title,
    }]

    sce = sl.ZhiZhaoYe
    sce.relations = [{
      contentId: sl.HuaGong.contentId,
      title: sl.HuaGong.title,
    }, {
      contentId: sl.WeiHuaPing.contentId,
      title: sl.WeiHuaPing.title,
    }]

    sce = sl.HuaGong
    sce.relations = [{
      contentId: sl.ZhiZhaoYe.contentId,
      title: sl.ZhiZhaoYe.title,
    }, {
      contentId: sl.WeiHuaPing.contentId,
      title: sl.WeiHuaPing.title,
    }]

    sce = sl.NongYe
    sce.relations = [{
      contentId: sl.ZhiZhaoYe.contentId,
      title: sl.ZhiZhaoYe.title,
    }, {
      contentId: sl.HuaGong.contentId,
      title: sl.HuaGong.title,
    }]
  },
  getItem (contentId = '') {
    let result = {}
    const me = dtScenes
    for (const i in me.items) {
      const item = me.items[i]
      if (item.contentId === contentId) {
        result = item
      }
    }
    return result
  },
  getItems (type = '', limit = 0) {
    const me = dtScenes
    const result = []
    if (type) {
      const arr = type.split('|')
      for (const i in me.items) {
        const item = me.items[i]
        if (arr.indexOf(item.type) !== -1) {
          result.push(item)
        }
      }

      return result
    } else {
      if (limit) {
        const len = me.items.length
        let cnt = limit
        if (cnt > len) {
          cnt = len
        }
        for (let i = 0; i < cnt; i++) {
          const item = me.items[i]
          result.push(item)
        }
        return result
      } else {
        return me.items
      }
    }
  },
}

export default dtScenes

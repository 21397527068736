var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('land-img',{attrs:{"flat":"","tile":"","height":_vm.wdHeight,"width":"100%","src":_vm.content.cover}},[_c('v-container',{staticStyle:{"max-width":"1200px","height":"90%"}},[_c('v-row',{staticClass:"ma-0 fill-height",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"7","sm":"12"}},[_c('div',{class:[
            'd-flex', 'flex-column', 'justify-space-around',
            _vm.classify.align ? ("align-" + (_vm.classify.align)) : 'align-center'
          ]},[_c('div',{class:[
              'd-flex', 'flex-column',
              _vm.classify.align ? ("align-" + (_vm.classify.align)) : 'align-center'
            ]},[(_vm.content.title)?_c('div',{class:['text-h5', 'text-md-h4', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],domProps:{"textContent":_vm._s(_vm.content.title)}}):_vm._e(),(_vm.content.subtitle)?_c('div',{class:[_vm.smAndDown ? 'co-text-sm' : 'co-text-md',, 'mt-6', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],staticStyle:{"font-weight":"400"},domProps:{"innerHTML":_vm._s(_vm.content.subtitle)}}):_vm._e()]),_c('div',{class:[
              'd-flex', 'flex-column',
              _vm.classify.align ? ("align-" + (_vm.classify.align)) : 'align-center'
            ]},[(_vm.button.text)?_c('v-btn',{class:[_vm.button.foreColor ? ((_vm.button.foreColor) + "--text") : 'primary--text', 'mt-8'],attrs:{"depressed":"","color":_vm.button.backColor || 'white',"width":_vm.btnWidth,"height":"40"},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.button.text)+" ")]):_vm._e(),(_vm.content.contact)?_c('div',{class:['text-h6', 'mt-3', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],domProps:{"textContent":_vm._s(_vm.content.contact)}}):_vm._e()],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
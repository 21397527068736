
import app from '@/api/co.app'

const SchemeSimples = {
  JiuDian: {
    contentId: '20001',
    title: '酒店房间人数实时监测解决方案',
    cover: app.base.res_url + '/img/sch-jiudian.jpg',
  },
  HuaGong: {
    contentId: '20002',
    title: '制造业安全生产解决方案',
    cover: app.base.res_url + '/img/sch-zhizaoye.jpg',
  },
  WeiSheng: {
    contentId: '20003',
    title: '卫生机构安全生产解决方案',
    cover: app.base.res_url + '/img/sce-hospital.jpg',
  }
}

export default SchemeSimples


const apiLink = {
  home: {
    path: '/',
    name: 'Home',
    title: '首页'
  },
  scene: {
    name: 'Scene',
    title: '应用场景'
  },
  scheme: {
    name: 'Scheme',
    title: '解决方案'
  },
  support: {
    name: 'Support',
    title: '技术支持'
  },
  about: {
    name: 'About',
    title: '关于我们'
  },
  sceneDetail: {
    path: '/scene/detail',
    name: 'SceneDetail',
    title: '场景详情'
  },
  schemeDetail: {
    path: '/scheme/detail',
    name: 'SchemeDetail',
    title: '方案详情'
  },
  my: {
    path: '/my',
    name: 'My',
    title: '个人中心'
  },
  login: {
    path: '/my/login',
    name: 'Login',
    title: '登录'
  },
  album: {
    path: '/my/album',
    name: 'Album',
    title: '我的影集'
  },
  info: {
    path: '/my/info',
    name: 'Info',
    title: '个人设置'
  },
  messages: {
    path: '/my/messages',
    name: 'Messages',
    title: '消息中心'
  },
}

export default apiLink

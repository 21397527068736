var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:[_vm.margin],attrs:{"no-gutters":false}},_vm._l((_vm.items),function(item,i){return _c('v-col',{key:("gallery_" + i),staticClass:"land-gallery",attrs:{"cols":"12","sm":_vm.smCols,"md":_vm.mdCols}},[_c('v-hover',{attrs:{"disabled":!_vm.hovered,"close-delay":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:[_vm.round, _vm.padding, 'transition-swing',
                 _vm.scaled && hover ? 'land-img-scale' : ''
        ],attrs:{"elevation":hover ? _vm.hoverElevation : _vm.normalElevation,"color":_vm.backColor,"flat":"","tile":""}},[(item.cover)?_c('v-img',{attrs:{"aspect-ratio":_vm.ratio,"src":item.cover,"height":"inherit"}},[_c('div',{staticClass:"co-flex-col co-justify-between co-h-full"},[_c('div',{staticClass:"co-flex-col co-w-full co-flex-grow ",on:{"click":function($event){return _vm.onClick(item, i)}}}),(_vm.titled && !_vm.outside && item.title)?_c('div',{staticClass:"co-flex-row co-justify-between co-items-center grey darken-4 co-h48 px-4",staticStyle:{"opacity":"0.70"},on:{"click":function($event){return _vm.onClick(item, i)}}},[_c('span',{staticClass:"text-body-1 white--text"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e()])]):_vm._e(),(_vm.titled && _vm.outside && item.title)?_c('div',{class:[
            'text-uppercase', 'co-text-sm',
            'px-4', 'py-2', _vm.textAlign, 'co-text-line1'
          ],staticStyle:{"line-height":"32px"},on:{"click":function($event){return _vm.onClick(item, i)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)]}}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
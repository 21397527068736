<template>
  <v-row
    :class="[margin]"
    :no-gutters="false"
  >
    <v-col
      v-for="(item, i) in items"
      :key="`gallery_${i}`"
      class="land-gallery"
      cols="12"
      :sm="smCols"
      :md="mdCols"
    >
      <v-hover
        v-slot="{ hover }"
        :disabled="!hovered"
        close-delay="70"
      >
        <v-card
          :elevation="hover ? hoverElevation : normalElevation"
          :class="[round, padding, 'transition-swing',
                   scaled && hover ? 'land-img-scale' : ''
          ]"
          :color="backColor"
          flat
          tile
        >
          <v-img
            v-if="item.cover"
            :aspect-ratio="ratio"
            :src="item.cover"
            height="inherit"
          >
            <div class="co-flex-col co-justify-between co-h-full">
              <div
                class="co-flex-col co-w-full co-flex-grow "
                @click="onClick(item, i)"
              />
              <div
                v-if="titled && !outside && item.title"
                class="co-flex-row co-justify-between co-items-center grey darken-4 co-h48 px-4"
                style="opacity: 0.70;"
                @click="onClick(item, i)"
              >
                <span
                  class="text-body-1 white--text"
                >
                  {{ item.title }}
                </span>
              </div>
            </div>
          </v-img>
          <div
            v-if="titled && outside && item.title"
            :class="[
              'text-uppercase', 'co-text-sm',
              'px-4', 'py-2', textAlign, 'co-text-line1'
            ]"
            style="line-height: 32px"
            @click="onClick(item, i)"
          >
            {{ item.title }}
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>

  export default {
    name: 'LandGallery',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      type: {
        type: String,
        default: 'image'
      },
      ratio: {
        // 默认为 5:3
        type: [Number, String],
        default: 1.67
      },
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      titled: {
        type: Boolean,
        default: true
      },
      outside: {
        type: Boolean,
        default: true
      },
      scaled: {
        type: Boolean,
        default: false
      },
      titleAlign: {
        type: String,
        default: 'left'
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 24
      }
    },
    data () {
      return {
        perCols: {
          c1: {
            sm: 12,
            md: 12
          },
          c2: {
            sm: 12,
            md: 6
          },
          c3: {
            sm: 6,
            md: 4
          },
          c4: {
            sm: 6,
            md: 3
          },
        },
        titleAligns: {
          left: 'text-left',
          center: 'text-center',
          right: 'text-right'
        }
      }
    },
    computed: {
      smCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.sm
      },
      mdCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.md
      },
      textAlign () {
        return this.titleAligns[this.titleAlign] || this.titleAligns.left
      }
    },
    methods: {
      onAction (ev, item, index) {
        const params = {
          index,
          item,
          action: ev
        }
        this.$emit('action', params)
      },
      onClick (item, index) {
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>

<style lang="sass">
  .land-gallery
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)

  .land-overlay
    height: 56px
    left: 0 !important
    right: 0 !important
    bottom: 0 !important
    top: auto !important

  .land-img-scale
    transform: scale(1.12, 1.12)
    z-index: 10
</style>

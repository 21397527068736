
import about from '@/data/data.about'
import banner from '@/data/data.banner'
import comm from '@/data/data.comm'
import scene from '@/data/data.scene'
import scheme from '@/data/data.scheme'
import support from '@/data/data.support'

const coData = {
  points: {
    major: {
      xs: 200,
      sm: 320,
      md: 410,
      lg: 720,
      xl: 800
    },
    minor: {
      xs: 200,
      sm: 320,
      md: 410,
      lg: 480,
      xl: 480
    }
  },
  height: {
    major: 720,
    minor: 480,
  },
  nav: {
    home: {
      text: '首页',
      disabled: false,
      href: '/',
    },
    scene: {
      text: '应用场景',
      disabled: false,
      href: '/scene',
    },
    scheme: {
      text: '解决方案',
      disabled: false,
      href: '/scheme',
    },
    current: {
      text: '',
      disabled: true,
      href: '',
    },
  },
  about,
  banner,
  comm,
  scene,
  scheme,
  support,
  initialize () {
    scene.initialize()
  },
}

export default coData

<template>
  <land-img
    flat
    tile
    :height="wdHeight"
    width="100%"
    :src="content.cover"
  >
    <v-container
      style="max-width: 1200px; height: 90%;"
    >
      <v-row
        class="ma-0 fill-height"
        align="center"
      >
        <v-col
          cols="12"
          md="7"
          sm="12"
        >
          <div
            :class="[
              'd-flex', 'flex-column', 'justify-space-around',
              classify.align ? `align-${classify.align}` : 'align-center'
            ]"
          >
            <div
              :class="[
                'd-flex', 'flex-column',
                classify.align ? `align-${classify.align}` : 'align-center'
              ]"
            >
              <div
                v-if="content.title"
                :class="['text-h5', 'text-md-h4', classify.color ? `${classify.color}--text` : 'white--text']"
                v-text="content.title"
              />

              <div
                v-if="content.subtitle"
                :class="[smAndDown ? 'co-text-sm' : 'co-text-md',, 'mt-6', classify.color ? `${classify.color}--text` : 'white--text']"
                style="font-weight: 400;"
                v-html="content.subtitle"
              />
            </div>

            <!-- <div
              v-if="!smAndDown && features.length"
              class="d-flex flex-row justify-between align-center"
            >
              <div
                v-for="(feature, index) in features"
                :key="`feature_${index}`"
                class="d-flex align-center"
              >
                <v-icon
                  v-if="feature.icon"
                  :color="feature.iconColor || 'white'"
                  class="mr-2"
                  size="32"
                >
                  {{ feature.icon }}
                </v-icon>

                <div
                  v-if="feature.text"
                  :class="['text-subtitle-1', 'text-md-h5', classify.color ? `${classify.color}--text` : 'white--text']"
                  style="font-weight: 400;"
                  v-text="feature.text"
                />
                <div
                  v-if="index < (features.length - 1)"
                  :key="`spacer_${index}`"
                  class="pa-2 pa-md-4"
                />
              </div>
            </div> -->

            <div
              :class="[
                'd-flex', 'flex-column',
                classify.align ? `align-${classify.align}` : 'align-center'
              ]"
            >
              <v-btn
                v-if="button.text"
                depressed
                :color="button.backColor || 'white'"
                :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text', 'mt-8']"
                :width="btnWidth"
                height="40"
                @click="onClick"
              >
                {{ button.text }}
              </v-btn>

              <div
                v-if="content.contact"
                :class="['text-h6', 'mt-3', classify.color ? `${classify.color}--text` : 'white--text']"
                v-text="content.contact"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </land-img>
</template>

<script>
  export default {
    name: 'LandSummary',
    props: {
      classify: {
        type: Object,
        default: () => {
          return {}
        }
      },
      content: {
        type: Object,
        default: () => {
          return {}
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        inPoints: {
          xs: 360,
          sm: 360,
          md: 500,
          lg: 620,
          xl: 620
        },
        inHeight: 500,
        features: []
      }
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const pts = this.classify.points || {}
        const ht = pts[name] || this.inPoints[name]
        return ht
      },
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return false
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 150
        } else {
          return 150
        }
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    watch: {
      content: {
        handler (val) {
          this.features = val.features || []
        },
        immediate: true
      }
    },
    methods: {
      onClick (ev) {
        const params = {
          content: this.content
        }

        this.$emit('click', params)
      }
    }
  }
</script>

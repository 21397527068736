
import app from '@/api/co.app'

const dtComm = {
  home: {
  },
  guidePeople: {
    cover: app.base.res_url + '/img/dev-people.jpg',
    header: {
      title: '云采-T8动态感知终端',
      english: '',
      subtitle: '通过智能化和信息化手段监督酒店、网约房合规化',
    },
  },
  guideEnv: {
    cover: app.base.res_url + '/img/dev-env.jpg',
    header: {
      title: '云采-A6环境监测终端',
      english: '',
      subtitle: '构建更加科学、高效、便捷的安全生产管理体系',
    },
  },
  foot: {
    cover: app.base.res_url + '/img/foot-backimage2.jpg',
    about: {
      name: 'about',
      title: '关于我们',
      items: [{
        name: 'scene',
        text: '应用场景',
      }, {
        name: 'scheme',
        text: '解决方案',
      }, {
        name: 'demo',
        text: '产品演示',
      }, {
        name: 'cooperate',
        text: '合作方式',
      }]
    },
    support: {
      name: 'support',
      title: '技术支持',
      items: [{
        name: 'intro',
        text: '产品介绍',
      }, {
        name: 'manual',
        text: '产品手册',
      }, {
        name: 'upgrade',
        text: '产品升级',
      }, {
        name: 'contact',
        text: '联系方式',
      }]
    },
    qrcode: {
      title: '蓝库服务号',
      src: app.base.res_url + '/img/qrc-service.jpg'
    },
  },
}

export default dtComm

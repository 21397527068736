<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import app from '@/api/co.app'
  import data from '@/data/co.data'

  // import user from '@/api/co.user'
  // import socket from '@/api/co.socket'

  export default {
    name: 'App',
    metaInfo: {
      title: '蓝库',
      titleTemplate: `%s | ${app.init.title}`,
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    data () {
      return {
      }
    },
    created () {
      data.initialize()
      // socket.main.initialize()
      // this.presetParams()
      // const intv = 1000 * 60 * 60
      // setInterval(() => {
      //   user.token.refresh()
      // }, intv)
    },
    methods: {
      presetParams () {
      },
    }
  }
</script>

<style lang="scss">
  @import '~@/styles/land/co-style.scss';

  .video-icon-border {
    width: 80px;
    height: 80px;
    border-width: 2px;
    border-style: solid;
    border-radius: 40px;
  }

  .video-icon-bg-normal {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .video-icon-bg-active {
    background-color: rgba(115, 133, 159, 0.5);
  }

  .video-icon-sm-border {
    width: 48px;
    height: 48px;
    border-width: 2px;
    border-style: solid;
    border-radius: 30px;
  }

  .video-icon-sm-bg-normal {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .video-icon-sm-bg-active {
    background-color: rgba(115, 133, 159, 0.5);
  }

  .extra-padding {
    padding-bottom: 96px !important;
    padding-top: 96px !important;

    @media screen and (max-width: 959px) {
      padding-top: 48px !important;
      padding-bottom: 48px !important;
    }
  }

  .co-overflow-hidden {
    overflow: hidden !important;
  }

  .co-shrink-0 {
    flex-shrink: 0 !important;
  }

  .co-text-line1 {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .co-text-line2 {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }

  .co-box-boder {
    box-sizing: border-box !important;
  }

  .co-box-content {
    box-sizing: content-box !important
  }
</style>
